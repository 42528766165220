import { gql } from '@apollo/client';

export const CURRENT_ADMIN = gql`
query CurrentAdmin {
  currentAdmin {
    id
    fullname
    email
    phone
    photo
    status
    role_name
    roles
    created_at
  }
}
`;

export const GET_CONFIG = gql`
query Query {
  getConfig
}
`;

export const GET_ALL_COURIER = gql `
query GetAllCouriers($offset: Int) {
  getAllCouriers(offset: $offset) {
    id
    fullname
    email
    phone
    photo
    status
  }
}
`;
export const GET_COURIER = gql `
query GetCourier($getCourierId: Int) {
  getCourier(id: $getCourierId) {
    id
    fullname
    email
    phone
    photo
    balance
    category
    successful_orders
    courierDocument {
      nin_status
      riders_license_status
      nin_front
      nin_back
      riders_license_front
      riders_license_back
      riders_license_expiry
      id
      user_id
    }
    courierDetails {
      bike_photo
      bike_model
      bike_brand
      bike_year
      bike_plate_number
      status
      id
      user_id
      current_location
      is_online
      is_busy
      last_seen
      created_at
    }
    rating
    created_at
  }
}
`;

export const GET_ROLES = gql `
query GetRoles {
  getRoles {
    id
    name
    json
    status
  }
}
`;
export const GET_ALL_MERCHANT = gql `
query GetAllMerchants($offset: Int) {
  getAllMerchants(offset: $offset) {
    id
    fullname
    email
    phone
    photo
    orders_no
    balance
    status
  }
}
`;
export const GET_PACKAGE_CATEGORIES = gql `
query GetPackageCategories {
  getPackageCategories {
    id
    name
    status
    created_at
  }
}
`;
export const GET_ALL_ADMIN_ACCOUNTS = gql `
query GetAllAdminAccounts($offset: Int) {
  getAllAdminAccounts(offset: $offset) {
    id
    fullname
    email
    phone
    photo
    status
    role_name
    roles
    created_at
  }
}
`;
export const GET_EMAIL_MESSAGE = gql `
query GetEmailMessage {
  getEmailMessage {
    id
    destination
    subject
    body
    status
    created_at
  }
}
`;
export const GET_AUDIT_TRAILS = gql `
query Query($offset: Int) {
  getAuditTrails(offset: $offset)
}
`;
export const GET_ALL_TRANSACTIONS = gql `
query GetAllTransactions($offset: Int) {
  getAllTransactions(offset: $offset) {
    id
    fullname
    date
    email
    amount
    status
    remarks
    created_at
    code
  }
}
`;
export const GET_ORDERS = gql `
query GetOrder($offset: Int) {
  getOrder(offset: $offset) {
    id
    order_code
    package_name
    sender_name
    package_quantity
    receiver_name
    receiver_phone
    pickup_address
    receiver_address
    amount
    status
    cancellation_reason
    delivered_at
    created_at
  }
}
`;
export const GET_DASHBOARD_DATA = gql `
query Query($timeFrame: String) {
  getDashboardData(time_frame: $timeFrame)
}
`;
export const ORDER_STATUS_STATISTICS = gql `
query Query($timeFrame: String) {
  orderStatusStatistics(time_frame: $timeFrame)
}
`;
export const GET_MERCHANT_NOTIFICATION = gql `
query GetMerchantNotifications($offset: Int) {
  getMerchantNotifications(offset: $offset) {
    id
    merchant_name
    message
    status
    created_at
  }
}
`;
export const GET_COURIER_NOTIFICATION = gql `
query GetCourierNotifications($offset: Int) {
  getCourierNotifications(offset: $offset) {
    id
    merchant_name
    message
    status
    created_at
  }
}
`;

export const WITHDRAWAL_REQUESTS = gql`
query Query($offset: Int) {
  withdrawalRequests(offset: $offset)
}
`;